var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"loading-text":"Yükleniyor","server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage,"footer-props":{
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      disableItemsPerPage: true,
      showFirstLastPage: true,
    },"dense":"","show-select":"","item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('rs-table-title-bar',{attrs:{"title":"","icon":"","hide-edit":"","search":_vm.search.query},on:{"clear-filters":function($event){_vm.search = {}},"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},[_c('template',{slot:"buttons:append"},[_c('v-btn',{staticClass:"ms-2",attrs:{"color":"pink","outlined":"","small":"","disabled":_vm.selectedItems.length !== 1},on:{"click":function($event){_vm.showAutoAssessmentForm = true}}},[_vm._v(" Makbuz Yazdır ")])],1)],2)]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{attrs:{"headers":headers,"search":_vm.search}})]}},{key:"item.door_number",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
          name: 'incomes.houses.show',
          params: { id: item.id },
        }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.balance",fn:function({ value }){return [(value)?_c('span',{class:_vm.getBalanceClass(value)},[_vm._v(" "+_vm._s(value.toLocaleString())+" ")]):_c('span',[_vm._v("0.00")])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }