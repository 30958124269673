<template>
  <div class="page page-company-form">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            <template v-if="house">
              {{ house.block.name }} - {{ house.door_number }}
            </template>
            Hesap Bilgileri
          </h3>
        </div>
        <v-col class="text-right">
          <v-btn
            class="ms-2 mb-2"
            color="pink"
            outlined
            v-if="
              $refs.confirmFutureOwner &&
              house &&
              house.future_owner_id &&
              can('edit-house')
            "
            @click="handleFutureOwnerClick"
            small
          >
            Tapu Devret
          </v-btn>

          <rs-confirm
            ref="confirmFutureOwner"
            :loading="isLoading"
            @confirmed="handleFutureOwnerSubmitClick"
            @cancelled="$refs.confirmFutureOwner.hide()"
          />

          <v-btn
            class="ms-2 mb-2"
            color="pink"
            outlined
            @click="$refs.ownerForm.show(null, { house_id: id })"
            small
            v-if="
              can('edit-house') && $refs.ownerForm && selectedTab !== 'renter'
            "
          >
            <template>Ev Sahibi Tanımla</template>
          </v-btn>
          <v-btn
            class="ms-2 mb-2"
            color="pink"
            outlined
            @click="$refs.renterForm.show(null, { house_id: id })"
            small
            v-if="can('edit-house') && selectedTab !== 'owner'"
          >
            Kiracı Tanımla
          </v-btn>
          <v-btn
            class="ms-2 mb-2"
            color="pink"
            outlined
            v-if="can('edit-house-income') && $refs.openningForm"
            @click="$refs.openningForm.show(id)"
            small
          >
            Açılış Alacak Fişi
          </v-btn>

          <v-menu
            offset-y
            bottom
            left
            v-if="house && canOr('edit-resident', 'show-resident')"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ms-2 mb-2"
                color="pink"
                v-bind="attrs"
                v-on="on"
                outlined
                small
              >
                Kişiler
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in house.house_users"
                :key="item.resident_id"
                :to="{
                  name: can('edit-resident')
                    ? 'definitions.residents.edit'
                    : 'definitions.residents.show',
                  params: { id: item.resident_id },
                }"
              >
                <v-list-item-title>{{ item.resident.name }}</v-list-item-title>
                <br />
                <v-list-item-subtitle>
                  {{ $t("house.user_type." + item.house_user_type_id) }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            class="ms-2 mb-2"
            color="pink"
            outlined
            small
            v-if="house && canOr('edit-house', 'show-house')"
            :to="{
              name: can('edit-house')
                ? 'definitions.houses.edit'
                : 'definitions.houses.show',
              params: { id: house.id },
            }"
            title="Bağımsız Bölüm Tanımına Git"
          >
            <v-icon small dense>mdi mdi-home</v-icon>
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </div>

      <div class="card-body">
        <v-tabs v-model="selectedTab">
          <v-tab v-for="tab in availableTabs" :key="tab.id" :to="'#' + tab.id">
            {{ tab.text }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            class="py-4"
            v-for="tab in availableTabs"
            :key="tab.id"
            :value="tab.id"
          >
            <rs-table-title-bar
              title=""
              icon=""
              @clear-filters="handleRemoveFiltersClick"
              @reload="load()"
              hide-edit
              hide-search
              hide-filter-clear
            >
              <template slot="buttons:prepend">
                <v-btn
                  v-if="can('edit-house-income')"
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.debtForm.show(null, null, { house_id: id })"
                >
                  Borç Fişi Oluştur
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="showFreeCollectingForm"
                  accesskey="1"
                  v-if="can('edit-house-income') && !debtCollectingEnabled"
                >
                  Serbest Tahsilat
                </v-btn>
                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.debtCollectingForm.show()"
                  v-if="can('edit-house-income') && debtCollectingEnabled"
                >
                  Borç Tahsilat
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.applyDeferForm.show()"
                  v-if="
                    can('edit-house-income') &&
                    applyDeferEnabled &&
                    selectedItems.length === 1
                  "
                >
                  Gecikme Uygula
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="handleCancelDeferClick"
                  v-if="can('edit-house-income') && cancelDeferEnabled"
                >
                  Gecikme İptal
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.collectingDebtMatchForm.show(id)"
                  v-if="can('edit-house-income') && collectingDebtMatchEnabled"
                >
                  Avans Borç Eşleştir
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.refundCollectingForm.show"
                  v-if="can('edit-house-income') && refundCollectingEnabled"
                >
                  Avans İade
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.enforcementForm.show()"
                  v-if="can('edit-house-income') && enforcementEnabled"
                >
                  İcra Başlat
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.debtShareForm.show"
                  v-if="can('edit-house-income') && debtShareEnabled"
                >
                  Borç Paylaştır
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click="$refs.collectingShareForm.show"
                  v-if="can('edit-house-income') && collectingShareEnabled"
                >
                  Avans Paylaştır
                </v-btn>

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  v-if="can('edit-house-income') && applyDeferEnabled"
                  @click="handleCloseDebtClick"
                >
                  Borç Kapat / Gider
                </v-btn>

                <!-- <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  v-if="applyDeferEnabled"
                  @click="handleCloseDebtClick"
                >
                  Borç Sil
                </v-btn> -->

                <v-btn
                  class="me-1 mb-1 float-end"
                  color="pink"
                  outlined
                  small
                  @click.prevent="handleEnforcementClick"
                  v-if="
                    can('see-house-income') &&
                    selectedItems.length === 1 &&
                    selectedItems[0].has_enforcement
                  "
                >
                  İcra Dosyası
                </v-btn>
              </template>
            </rs-table-title-bar>

            <v-data-table
              class="no-wrap"
              :fixed-header="false"
              :height="undefined"
              v-bind="dataTableAttrs"
              v-if="cluster"
              :headers="headers"
              :items="listShown"
              :loading="isLoading"
              :options.sync="options"
              :items-per-page.sync="itemsPerPage"
              item-key="uniqueKey"
              :item-class="getRowClass"
              v-model="selectedItems"
              disable-sort
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:body.append="{ headers }">
                <rs-table-foot-totals
                  :headers="headers"
                  :totals="footTotals"
                  totals-text="Toplam"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.balance="{ value }">
                <rs-table-cell-balance colored normalized :value="value" />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.description="{ item, value }">
                <template v-if="item.debt_id || item.collecting_id">
                  <rs-table-cell-string limit="20" :value="value" />
                </template>
                <template v-else>
                  <strong>{{ item.house_user_type }}</strong>
                </template>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.resident_name="{ item, value }">
                <template v-if="item.debt_id || item.collecting_id">
                  <rs-table-cell-string :value="value" />
                </template>
                <template v-else>
                  <strong>{{ item.resident_name }}</strong>
                </template>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.assessment="{ value }">
                <rs-table-cell-number muted-zeros price :value="value" />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.payed_capital="{ value }">
                <rs-table-cell-number muted-zeros price :value="value" />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.defer_assessment="{ value }">
                <rs-table-cell-number muted-zeros price :value="value" />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.defer_payed="{ value }">
                <rs-table-cell-number muted-zeros price :value="value" />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.total_balance="{ value, item }">
                <rs-table-cell-balance
                  colored
                  :normalized="item.debt_id || item.collecting_id"
                  :value="value"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.balance_wo_enforcement="{ value }">
                <rs-table-cell-balance
                  colored
                  normalized
                  muted-zeros
                  :value="value"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.enforcement_balance="{ value, item }">
                <router-link
                  :to="{
                    name: 'enforcements.show',
                    params: { id: item.enforcement_id },
                  }"
                  v-if="
                    can('see-enforcement') && value > 0 && item.enforcement_id
                  "
                >
                  <rs-table-cell-balance :value="value" muted-zeros />
                </router-link>
                <router-link
                  :to="{
                    name: 'enforcements.list',
                    query: { resident: item.resident_name },
                  }"
                  v-else-if="
                    can('see-enforcement') && value > 0 && item.resident_name
                  "
                >
                  <rs-table-cell-balance :value="value" muted-zeros />
                </router-link>
                <rs-table-cell-balance v-else :value="value" muted-zeros />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.defer_balance="{ item, value }">
                <v-tooltip bottom v-if="item.stop_deferment_on">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" dark v-bind="attrs" v-on="on">
                      mdi-exclamation
                    </v-icon>
                  </template>
                  {{ new Date(item.stop_deferment_on).toLocaleDateString() }}
                  tarihinden itibaren gecikme işletilmeyecek.
                </v-tooltip>

                <v-tooltip
                  bottom
                  v-if="
                    !item.has_enforcement &&
                    !item.stop_deferment_on &&
                    item.deferment_date &&
                    new Date(item.last_payment_on) < new Date() &&
                    new Date(item.deferment_date).toLocaleDateString() !==
                      new Date().toLocaleDateString()
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="yellow darken-3"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-exclamation
                    </v-icon>
                  </template>
                  Gecikme hesabı
                  {{ new Date(item.deferment_date).toLocaleDateString() }}
                  tarihine göre yapıldı.
                </v-tooltip>

                <span>
                  <rs-table-cell-balance colored normalized :value="value" />
                </span>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.last_payment_on="{ value }">
                <rs-table-cell-date
                  :value="value"
                  :css-class="{
                    'text-danger font-weight-bold': value < new Date(),
                  }"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.type="{ item }">
                {{ item.income_type || item.collecting_type || null }}
              </template>
            </v-data-table>

            <v-snackbar
              :value="selectedDebtAmount"
              timeout="-1"
              class="text-end"
            >
              Seçilen Borçların Toplamı:
              <strong>
                <rs-table-cell-number price :value="selectedDebtAmount" />
              </strong>
            </v-snackbar>

            <v-divider class="mx-10" />

            <DebtTypeSummary :list="listShown" />

            <v-divider class="mx-10" />

            <v-tabs v-model="selectedTabSecondary">
              <v-tab href="#account-activity">
                {{ $t("headers.account_activity") }}
              </v-tab>
              <v-tab href="#resident-notes" v-if="can('edit-house-income')">
                Kişi Notları
              </v-tab>
              <v-tab href="#house-notes" v-if="can('edit-house-income')">
                BB Notları
              </v-tab>
              <v-tab href="#defer-info">{{ $t("headers.defer_info") }}</v-tab>
              <v-tab href="#share-meter-info" v-if="false">
                {{ $t("headers.share_meter_info") }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedTabSecondary">
              <v-tab-item value="account-activity">
                <div class="py-5">
                  <BalanceActivityList
                    :list-type="tab.id"
                    :house-id="id"
                    :house="house"
                    @update:balance="load"
                    v-if="id && house"
                    ref="balanceActivityList"
                  />
                </div>
              </v-tab-item>
              <v-tab-item value="resident-notes">
                <div class="py-5">
                  <NoteList
                    v-if="residentNoteListParams.extraParams"
                    :allow-add="residentNoteListParams.extraParams.resident_id"
                    v-bind="residentNoteListParams"
                  />
                </div>
              </v-tab-item>
              <v-tab-item value="house-notes">
                <div class="py-5">
                  <NoteList
                    :allow-add="true"
                    :extra-params="{ house_id: id }"
                    :form-subtitle="house ? house.door_number : null"
                  />
                </div>
              </v-tab-item>
              <v-tab-item value="defer-info">
                <div class="py-5">
                  <DeferInfo
                    :list-type="tab.id"
                    :house-id="id"
                    ref="deferInfo"
                  />
                </div>
              </v-tab-item>
              <v-tab-item value="share-meter-info">
                <div class="py-5">
                  <ShareMeterInfo :list-type="tab.id" />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-tab-item>
        </v-tabs-items>

        <OwnerForm
          v-if="house"
          ref="ownerForm"
          @saved="loadBothLists()"
          :house="house"
        />

        <RenterForm
          v-if="house"
          ref="renterForm"
          @saved="loadBothLists()"
          :house="house"
        />

        <OpenningDueReceiptForm
          ref="openningForm"
          :extra-params="{ house_id: id }"
          @saved="loadBothLists()"
          :house="house"
        />

        <EnforcementForm
          :house="house"
          ref="enforcementForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <FreeCollectingForm
          :house="house"
          ref="freeCollectingForm"
          :selected="selectedItems"
          :debt-list="listShown"
          @saved="loadBothLists()"
        />

        <DebtCollectingForm
          :house="house"
          ref="debtCollectingForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <ApplyDeferForm
          :house="house"
          ref="applyDeferForm"
          :selected="selectedItems"
          @saved="handleDeferSaved"
        />

        <CollectingShareForm
          :house="house"
          ref="collectingShareForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <DebtShareForm
          :house="house"
          ref="debtShareForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <RefundCollectingForm
          :house="house"
          ref="refundCollectingForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <CollectingDebtMatchForm
          :house="house"
          ref="collectingDebtMatchForm"
          :selected="selectedItems"
          :list="listShown"
          @saved="loadBothLists()"
        />

        <DebtForm :house="house" ref="debtForm" @saved="loadBothLists()" />
      </div>
    </div>

    <rs-confirm
      ref="confirmCancelDefer"
      :loading="isLoading"
      @confirmed="handleCancelDeferSubmitClick"
      @cancelled="$refs.confirmCancelDefer.hide()"
    />

    <rs-confirm
      ref="confirmCloseDebt"
      :loading="isLoading"
      @confirmed="handleCloseDebtSubmitClick"
      @cancelled="$refs.confirmCloseDebt.hide()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { hasDataTable, hasPermissions, isPage } from "@/view/mixins";
import OpenningDueReceiptForm from "@/view/content/houses/OpenningDueReceiptForm";
import OwnerForm from "@/view/content/houses/OwnerForm";
import RenterForm from "@/view/content/houses/RenterForm";
import NoteList from "@/view/content/Notes/NoteList";
import {
  ApplyDeferForm,
  CollectingDebtMatchForm,
  CollectingShareForm,
  DebtCollectingForm,
  DebtForm,
  DebtShareForm,
  EnforcementForm,
  FreeCollectingForm,
  RefundCollectingForm,
} from "@/view/pages/income/forms";
import BalanceActivityList from "./BalanceActivityList";
import DebtTypeSummary from "./DebtTypeSummary";
import DeferInfo from "./DeferInfo";
import ShareMeterInfo from "./ShareMeterInfo";

export default {
  name: "HouseDetail",
  components: {
    ApplyDeferForm,
    BalanceActivityList,
    CollectingDebtMatchForm,
    CollectingShareForm,
    DebtCollectingForm,
    DebtForm,
    DebtShareForm,
    DebtTypeSummary,
    DeferInfo,
    EnforcementForm,
    FreeCollectingForm,
    NoteList,
    OpenningDueReceiptForm,
    OwnerForm,
    RefundCollectingForm,
    RenterForm,
    ShareMeterInfo,
  },
  mixins: [hasDataTable, hasPermissions, isPage],
  props: {
    /**
     * House ID if editing
     */
    id: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["cluster", "currentUser"]),
    listShown() {
      if (!this.list.length && !this.totals.length) {
        return [];
      }
      // if (!this.totals.length) {
      //   return [];
      // }

      let listShown = [].concat(this.list);
      // let listShown = [];
      //
      // for (const index in this.list) {
      //   const item = this.list[index];
      //
      //   if (item.collecting_id) {
      //     listShown.push(item);
      //     continue;
      //   }
      //
      //   item.last_payment_on = new Date(item.last_payment_on);
      //
      //   if (item.last_payment_on < new Date()) {
      //     if (parseFloat(item.total_balance) !== 0) {
      //       listShown.push(item);
      //     }
      //   } else {
      //     listShown.push(item);
      //   }
      // }

      const totals = this.totals.map((item) => {
        item.isSelectable = false;
        item.itemClass = "bg-gray";
        return item;
      });

      listShown = listShown.concat(totals);

      listShown.sort((a, b) => {
        if (a.resident_id > b.resident_id) {
          return -1;
        } else if (a.resident_id < b.resident_id) {
          return 1;
        } else {
          if (a.itemClass === "bg-gray") {
            return -1;
          } else if (b.itemClass === "bg-gray") {
            return 1;
          }

          if (a.collecting_id && !b.collecting_id) {
            return -1;
          } else if (!a.collecting_id && b.collecting_id) {
            return 1;
          }

          if (a.house_user_type_id === -1 && b.house_user_type_id !== -1) {
            return 1;
          } else if (
            a.house_user_type_id !== -1 &&
            b.house_user_type_id === -1
          ) {
            return -1;
          }

          if (a.debt_id === null) {
            return -1;
          } else if (b.debt_id === null) {
            return 1;
          } else {
            return a.last_payment_on > b.last_payment_on ? 1 : -1;
          }
        }
      });

      listShown = listShown.map((item) => {
        item.uniqueKey = Math.random().toString().split(".")[1];
        return item;
      });

      return listShown;
    },
    residentNoteListParams() {
      const list = [];

      if (this.house === null) {
        return {};
      }

      if (this.selectedTab === "all") {
        for (const index in this.house.house_users) {
          const houseUser = this.house.house_users[index];
          list.push(houseUser.resident_id);
        }
      } else if (this.selectedTab === "owner") {
        for (const index in this.house.house_users) {
          const houseUser = this.house.house_users[index];
          if (houseUser.house_user_type_id === 1) {
            list.push(houseUser.resident_id);
          }
        }
      } else if (this.selectedTab === "renter") {
        for (const index in this.house.house_users) {
          const houseUser = this.house.house_users[index];
          if (houseUser.house_user_type_id === 2) {
            list.push(houseUser.resident_id);
          }
        }
      }

      if (list.length === 1) {
        return { extraParams: { resident_id: list[0] } };
      }

      return { extraParams: { resident_ids: list } };
    },
    debtCollectingEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      const residentId = this.selectedItems[0].resident_id;

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }

        if (parseFloat(item.total_balance) <= 0) {
          return false;
        }

        if (item.resident_id !== residentId) {
          return false;
        }
      }

      return true;
    },
    applyDeferEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }

        if (item.is_deferment) {
          return false;
        }

        if (item.income_type_id === 15) {
          return false;
        }

        if (parseFloat(item.total_balance) > 0) {
          continue;
        }

        return false;
      }

      return true;
    },
    cancelDeferEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }

        if (parseFloat(item.defer_balance) > 0) {
          continue;
        }

        return false;
      }

      return true;
    },
    enforcementEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      const firstResidentId = this.selectedItems[0].resident_id;

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];
        if (item.has_enforcement) {
          return false;
        }

        if (item.income_type_id === 15) {
          // Already an enforcement debt from previous management company
          return false;
        }

        if (item.resident_id !== firstResidentId) {
          return false;
        }

        if (parseFloat(item.total_balance) > 0 && !item.has_enforcement) {
          continue;
        }

        return false;
      }

      return true;
    },
    collectingShareEnabled() {
      if (
        this.selectedItems.length !== 1 ||
        this.selectedItems[0].total_balance > 0
      ) {
        return false;
      }

      return true;
    },
    debtShareEnabled() {
      if (
        this.selectedItems.length !== 1 ||
        this.selectedItems[0].total_balance < 0 ||
        this.selectedItems[0].defer_payed > 0
      ) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }

        if (item.income_type_id === 15) {
          return false;
        }
      }

      return true;
    },
    refundCollectingEnabled() {
      if (
        this.selectedItems.length !== 1 ||
        this.selectedItems[0].total_balance > 0
      ) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }
      }

      return true;
    },
    collectingDebtMatchEnabled() {
      if (
        this.selectedItems.length !== 1 ||
        this.selectedItems[0].total_balance > 0
      ) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }
      }

      return true;
    },
    selectedDebtAmount() {
      let totalDebtAmount = 0;

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];
        if (item.debt_id) {
          totalDebtAmount += item.total_balance * 100;
        }
      }

      return totalDebtAmount / 100;
    },
    availableTabs() {
      if (
        !this.currentUser ||
        !this.currentUser.id ||
        !this.totals.length ||
        !this.house
      ) {
        return [this.tabs[0]];
      }

      let tabs = [].concat(this.tabs);

      for (const index in this.totals) {
        if (this.totals[index].user_id === this.currentUser.id) {
          if (this.totals[index].house_user_type_id !== 1) {
            tabs = [this.tabs[0]];
          }
        }
      }

      if (tabs.length === 3) {
        let hasRenter = false;

        for (const index in this.house.house_users) {
          if (this.house.house_users[index].house_user_type_id === 2) {
            hasRenter = true;
            break;
          }
        }

        if (!hasRenter) {
          tabs.pop();
        }
      }

      return tabs;
    },
  },
  watch: {
    id(newValue, oldValue) {
      if (parseInt(newValue) !== parseInt(oldValue)) {
        this.load();
      }
    },
    selectedTab(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.load();
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load();
    });
  },
  data() {
    return {
      // isLoading: false,
      house: null,
      selectedTab: "all",
      selectedTabSecondary: null,
      // list: [],
      // options: {},
      footTotals: {},
      totals: [],
      tabs: [
        {
          id: "all",
          text: this.$t("labels.all"),
        },
        {
          id: "owner",
          text: this.$t("labels.owner"),
        },
        {
          id: "renter",
          text: this.$t("labels.renter"),
        },
      ],
      headers: [
        {
          text: "Fiş Türü",
          value: "type",
        },
        {
          text: this.$t("labels.description"),
          value: "description",
        },
        {
          text: this.$t("labels.name"),
          value: "resident_name",
        },
        {
          text: this.$t("labels.last_payment_date"),
          value: "last_payment_on",
          width: "120px",
        },
        {
          text: this.$t("labels.assessment"),
          value: "assessment",
          align: "end",
        },
        {
          text: this.$t("labels.payed"),
          value: "payed_capital",
          align: "end",
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          align: "end",
        },
        {
          text: this.$t("labels.total_balance"),
          value: "total_balance",
          align: "end",
        },
        {
          text: this.$t("labels.defer_assessment"),
          value: "defer_assessment",
          align: "end",
        },
        {
          text: this.$t("labels.defer_payed"),
          value: "defer_payed",
          align: "end",
        },
        {
          text: this.$t("labels.defer_balance"),
          value: "defer_balance",
          align: "end",
          width: "120px",
        },
        {
          text: "İcra Bakiye",
          value: "enforcement_balance",
          align: "end",
        },
        {
          text: "İcra Hariç Bakiye",
          value: "balance_wo_enforcement",
          align: "end",
        },
      ],
      originalData: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      if (!this.id || this.isLoading) {
        return;
      }

      this.selectedItems = [];
      this.isLoading = true;

      // Get house data
      this.$api
        .query(`houses/${this.id}`)
        .then((response) => {
          this.house = Object.assign({}, response.data.data);
          this.pageMeta.title =
            this.house.block.name +
            "-" +
            this.house.door_number +
            " Mali İşlemler";

          this.updateClusterId(this.house.block.cluster_id);
        })
        .catch((error) => {
          this.handleError(error);
        });

      this.list = [];
      this.selectedItems = [];
      this.footTotals = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.selectedTab !== "all") {
        if (this.selectedTab === "owner") {
          params.house_user_type_id = 1;
        } else {
          params.house_user_type_id = 2;
        }
      }

      return this.$api
        .query(`income/houses/${this.id}`, { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals.pop();
            this.totals = response.data.meta.totals;
          }

          // this.setOwnersList();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.isLoading = false;
        });
    },
    handleDeferSaved() {
      this.loadBothLists();
      if (
        this.$refs.deferInfo &&
        this.$refs.deferInfo.constructor.name === "Array"
      ) {
        this.$refs.deferInfo[0].loadList();
      }
    },
    handleCancelDeferClick() {
      this.$refs.confirmCancelDefer.show(
        "Seçili borçlarda gecikme borçlarını silmek istediğinizden emin misiniz?"
      );
    },
    handleCancelDeferSubmitClick() {
      this.$refs.confirmCancelDefer.hide();
      this.isLoading = true;

      const promises = [];

      for (const index in this.selectedItems) {
        promises.push(this.cancelDeferment(this.selectedItems[index]));
      }

      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.loadBothLists();
          }, 100);
        })
        .catch(() => {
          this.$toast.error(
            "Bir veya birden fazla borcun gecikmesi silinemedi"
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancelDeferment(item) {
      return this.$api
        .delete(`income/debts/${item.debt_id}/deferment`)
        .then(() => {
          this.$toast.success("Gecikme silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleCloseDebtClick() {
      this.$refs.confirmCloseDebt.show(
        "Seçili borçları gider olarak kapatmak istediğinizden emin misiniz?"
      );
    },
    handleCloseDebtSubmitClick() {
      this.$refs.confirmCloseDebt.hide();

      if (this.isLoading || !this.applyDeferEnabled) {
        return false;
      }

      this.isLoading = true;
      const list = [];

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];
        list.push({
          debt_id: item.debt_id,
          house_id: this.id,
          resident_id: item.resident_id,
          amount: item.total_balance,
          income_type_id: 1001, // LOSS
        });
      }

      return this.$api
        .post("expense/other-expenses/store-multiple", { data: list })
        .then(() => {
          this.$toast.success("Diğer giderler oluşturuldu");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadBothLists();
        });
    },
    getRowClass(item) {
      const lighten = "lighten-5";
      if (item.enforcement_id) {
        return "red lighten-4";
      } else if (item.collecting_id) {
        return "green " + lighten;
      } else if (item.debt_id === undefined) {
        return "font-weight-bold blue lead " + lighten;
      }
      return "";
    },
    showFreeCollectingForm() {
      if (this.selectedTab === "owner") {
        let ownerId;
        for (const index in this.house.house_users) {
          const item = this.house.house_users[index];
          if (item.house_user_type_id === 1) {
            if (ownerId === undefined) {
              ownerId = item.resident_id;
            } else {
              this.$refs.freeCollectingForm.show();
              return;
            }
          }
        }

        if (ownerId !== undefined) {
          this.$refs.freeCollectingForm.show(ownerId);
          return;
        }
      } else if (this.selectedTab === "renter") {
        for (const index in this.house.house_users) {
          const item = this.house.house_users[index];
          if (item.house_user_type_id === 2) {
            this.$refs.freeCollectingForm.show(item.resident_id);
            return;
          }
        }
      }

      this.$refs.freeCollectingForm.show();
    },
    loadBothLists() {
      if (this.$refs.balanceActivityList) {
        if (this.$refs.balanceActivityList.constructor.name === "Array") {
          this.$refs.balanceActivityList[0].loadList();
        } else {
          this.$refs.balanceActivityList.loadList();
        }
      }
    },
    handleFutureOwnerClick() {
      this.$refs.confirmFutureOwner.show(
        `Ev sahibini ${this.house.future_owner.name} olarak değiştirmek istediğinizden emin misiniz?`
      );
    },
    handleFutureOwnerSubmitClick() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .post(`houses/${this.id}/transfer-to-future-owner`)
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.$refs.confirmFutureOwner.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.load();
        });
    },
    handleEnforcementClick(item) {
      let id;

      if (item && item.enforcement_id) {
        id = item.enforcement_id;
      } else if (this.selectedItems.length === 1) {
        id = this.selectedItems[0].enforcement_id;
      }

      if (!id) {
        return false;
      }

      this.$router.push({
        name: "enforcements.edit",
        params: { id: id },
      });
    },
  },
};
</script>

<style media="screen" lang="scss">
.top-buttons {
  .v-btn:not(.v-btn--round).v-size--small {
    padding: 0 5px;
  }
}
</style>
