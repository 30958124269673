<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      loading-text="Yükleniyor"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 30, 50, 100],
        disableItemsPerPage: true,
        showFirstLastPage: true,
      }"
      dense
      show-select
      item-key="id"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          title=""
          icon=""
          @clear-filters="search = {}"
          @reload="loadList"
          hide-edit
          :search.sync="search.query"
        >
          <template slot="buttons:append">
            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="showAutoAssessmentForm = true"
              :disabled="selectedItems.length !== 1"
            >
              Makbuz Yazdır
            </v-btn>
          </template>
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search :headers="headers" :search="search" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.door_number="{ item, value }">
        <router-link
          :to="{
            name: 'incomes.houses.show',
            params: { id: item.id },
          }"
        >
          {{ value }}
        </router-link>
      </template>

      <!-- eslint-disable -->
      <template v-slot:item.balance="{ value }">
        <span :class="getBalanceClass(value)" v-if="value">
          {{ value.toLocaleString() }}
        </span>
        <span v-else>0.00</span>
      </template>
      <!-- eslint-enable -->
    </v-data-table>
  </div>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { buildSearchParams, getBalanceClass } from "@/core/helpers";
import { hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasDataTable],
  props: {
    listType: {
      type: String,
      required: false,
      default: "all",
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  data() {
    return {
      headers: [
        {
          text: "Pay Ölçer Sistem No",
          value: "bill_no",
          searchable: "text",
        },
        {
          text: "Dönem Kodu",
          value: "period_code",
          searchable: "string",
        },
        {
          text: this.$t("labels.area"),
          value: "area",
          searchable: "number",
          align: "end",
        },
        {
          text: "Genel Toplam Tutar",
          value: "total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Borçlu",
          value: "debt_owner",
          searchable: "select",
          options: [],
          itemText: "name",
        },
        {
          text: "Ödendi mi",
          value: "is_payed",
          searchable: "yesNo",
        },
        {
          text: this.$t("labels.payment_date"),
          value: "payment_date",
          searchable: "date",
        },
        {
          text: "Sıcak Su İlk Endeks",
          value: "hot_water_first_index",
          searchable: "number",
          align: "end",
        },
        {
          text: "Sıcak Su Son Endeks",
          value: "hot_water_last_index",
          searchable: "number",
          align: "end",
        },
        {
          text: "Sıcak Su Fark Endeks",
          value: "hot_water_index_diff",
          searchable: "number",
          align: "end",
        },
        {
          text: "Sıcak Su Ortak Tutar",
          value: "hot_water_shared_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Sıcak Su Tutar",
          value: "hot_water_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Sıcak Su Toplam Tutar",
          value: "hot_water_total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Soğuk Su İlk Endeks",
          value: "cold_water_first_index",
          searchable: "number",
          align: "end",
        },
        {
          text: "Soğuk Su Son Endeks",
          value: "cold_water_last_index",
          searchable: "number",
          align: "end",
        },
        {
          text: "Soğuk Su Fark Endeks",
          value: "cold_water_index_diff",
          searchable: "number",
          align: "end",
        },
        {
          text: "Soğuk Su Ortak Tutar",
          value: "cold_water_shared_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Soğuk Su Tutar",
          value: "cold_water_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Soğuk Su Toplam Tutar",
          value: "cold_water_total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalorimetre İlk Endeks",
          value: "calorimeter_first_index",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalorimetre Son Endeks",
          value: "calorimeter_last_index",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalorimetre Fark Endeks",
          value: "hot_water_index_diff",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalorimetre Ortak Tutar",
          value: "calorimeter_shared_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalorimetre Tutar",
          value: "calorimeter_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalorimetre Toplam Tutar",
          value: "calorimeter_total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Okuma Bedeli",
          value: "process_fee",
          searchable: "number",
          align: "end",
        },
        {
          text: "Havuz Ortak Tutar",
          value: "shared_amount",
          searchable: "number",
          align: "end",
        },
      ],
      search: {
        query: null,
      },
      total: null,
      options: {},
      list: [],
      page: 1,
      isLoading: false,
      itemsPerPage: 20,
      selectedItems: [],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        buildSearchParams(this.search, this.options)
      );

      this.$api
        .query("houses", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getBalanceClass: getBalanceClass,
  },
};
</script>
